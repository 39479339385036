
  import { defineComponent, ref, watch, computed } from 'vue';
  import { useI18n } from 'vue-i18n/index';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useStore } from 'vuex';
  import { useRoute } from 'vue-router';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';
  import Multiselect from '@vueform/multiselect';
  import { ShipmentsFilterListInfo } from '@/store/modules/Shipments/ShipmentsModule';
  export default defineComponent({
    name: 'Shipments-filter',
    emit: ['type-selected', 'reset'],
    components: { Multiselect },
    async setup(props, { emit }) {
      const { t, te } = useI18n();
      const store = useStore();
      const route = useRoute();
      const selectedGovernorate = ref<string>('');
      const selectedArea = ref<Array<string>>([]);
      const selectedHubs = ref<Array<string>>([]);
      const pathRoute = ref<string>(route.path);

      const selectedItems = ref<ShipmentsFilterListInfo>({
        officeId: '',
        trackNumber: '',
        status: '',
        packageTrackNumber: '',
        phoneNumber: '',
        docId: '',
        name: '',
        driverName: '',
        packageStatusId: '',
        areaId: [],
        governorateId: '',
        startDate: '',
        endDate: '',
        hubId: [],
      });

      if (route.name) {
        selectedArea.value = [];
        selectedGovernorate.value = '';
        selectedHubs.value = [];
        selectedItems.value = {
          officeId: '',
          trackNumber: '',
          status: '',
          packageTrackNumber: '',
          phoneNumber: '',
          docId: '',
          name: '',
          driverName: '',
          packageStatusId: '',
          areaId: [],
          governorateId: '',
          startDate: '',
          endDate: '',
          hubId: [],
        };
        emit('reset', selectedItems.value);
      }

      watch(
        () => selectedGovernorate.value,
        async () => {
          if (selectedGovernorate.value) {
            selectedArea.value = [];
            await store.dispatch(
              Actions.GET_SHIPMENT_AREAS,
              selectedGovernorate.value
            );
          }
        }
      );

      watch(
        () => selectedGovernorate.value,
        (newGovernorate, oldGovernorate) => {
          selectedItems.value.governorateId = newGovernorate;
        }
      );

      watch(
        () => selectedArea.value,
        (newAreas, oldAreas) => {
          selectedItems.value.areaId = newAreas;
          console.log(newAreas);
        }
      );

      watch(
        () => selectedHubs.value,
        (newHubs, oldHubs) => {
          selectedItems.value.hubId = newHubs;
        }
      );

      const areas = computed(() => store.getters.getAllAreasList);
      const areasTitle = () => {
        return areas.value.map((i) => {
          return { title: i.titleTranslate, id: i.id };
        });
      };

      const governorate = computed(() => store.getters.allGovernoratesList);
      const governorateTitle = () => {
        return governorate.value.map((i) => {
          return {
            title: i.title.find((r) => r.languageCode === 'en')?.title,
            id: i.id,
          };
        });
      };

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const onSubmit = () => {
        emit('type-selected', selectedItems.value);
      };

      const onReset = () => {
        selectedArea.value = [];
        selectedGovernorate.value = '';
        selectedHubs.value = [];
        selectedItems.value = {
          officeId: '',
          trackNumber: '',
          status: '',
          packageTrackNumber: '',
          phoneNumber: '',
          docId: '',
          name: '',
          driverName: '',
          packageStatusId: '',
          areaId: [],
          governorateId: '',
          startDate: '',
          endDate: '',
          hubId: [],
        };
        emit('reset', selectedItems.value);
      };
      const officeData = await store.dispatch(Actions.GET_SHIPMENT_GW_OFFICES);
      const offices = officeData.data.filter(
        (office) => office.deletedat == null
      );

      const hubs = await store.dispatch(Actions.GET_ALL_HUBS_CREATING);

      const hubsTitle = () => {
        return hubs.map((r) => {
          return { title: r.titleTranslate, id: r.id };
        });
      };

      await store.dispatch(Actions.GET_ALL_GOVERNORATES);
      await store.dispatch(Actions.GET_HUB_AREAS);
      await store.dispatch(Actions.GET_ALL_SHIPMENT_STATUSES, 'all');
      reinitializeComponents();
      return {
        offices,
        selectedGovernorate,
        selectedArea,
        hubsTitle,
        selectedHubs,
        governorateTitle,
        areasTitle,
        pathRoute,
        onSubmit,
        shipmentStatuses: computed(() => store.getters.shipmentStatusesList),
        onReset,
        translate,
        selectedItems,
      };
    },
  });
